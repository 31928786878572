import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';

const NikkeGuidesLostRelicPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Lost Relics</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_lostrelics.png"
            alt="Lost Relics"
          />
        </div>
        <div className="page-details">
          <h1>Lost Relics</h1>
          <h2>What are Lost Relics and where to find them.</h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="About Lost Relics" />
        <p>
          Lost Relics are scattered around the map and to find them, you have to
          basically stand on top of them until a warning icon appears and then
          click it. It looks like that:
        </p>
        <StaticImage
          src="../../../images/nikke/lost/lost_icon.jpg"
          alt="Icon"
        />
        <p>
          Once you gather them, you can go to Command Center and then Lost
          Relics widget. This screen will appear to you:
        </p>
        <StaticImage
          src="../../../images/nikke/generic/lost_list.jpg"
          alt="Lost Relics"
        />
        <p>
          When you gather all Relics belonging to a collection, you will obtain
          100 Gems in return, so it's worth finding them all for some free Gems.
        </p>
        <p>
          Still on top of the collection items, there are other items scattered
          around the map:
        </p>
        <ul>
          <li>Currency,</li>
          <li>Materials,</li>
          <li>Blueprints (needed for building).</li>
        </ul>
        <SectionHeader title="Blueprints" />
        <p>
          On top of Relics, Blueprints also need to be find in the map, and to
          not force you to jump around the Chapter pages to find where each one
          spawns, here's the list of all Blueprints:
        </p>
        <ul>
          <li>Observatory Blueprint - Chapter 3</li>
          <li>Police Station Blueprint - Chapter 3</li>
          <li>Maid Cafe - Chapter 3</li>
          <li>Toy Shop - Chapter 3</li>
          <li>Train Station - Chapter 3</li>
          <li>Workshop - Chapter 4</li>
          <li>Armory - Chapter 4</li>
          <li>Generator - Chapter 4</li>
          <li>Trendy Bar - Chapter 5</li>
          <li>Library - Chapter 5</li>
          <li>Hospital - Chapter 5</li>
          <li>Cafe - Chapter 6</li>
          <li>Theater - Chapter 6</li>
          <li>Seedy Club - Chapter 6</li>
          <li>Courthouse - Chapter 7</li>
          <li>Church - Chapter 7</li>
          <li>Clothing Store - Chapter 8</li>
          <li>Shopping Mall - Chapter 8</li>
          <li>Radio Tower - Chapter 9</li>
          <li>Goddess of Victory - Chapter 9</li>
        </ul>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
    </DashboardLayout>
  );
};

export default NikkeGuidesLostRelicPage;

export const Head: React.FC = () => (
  <Seo
    title="Lost Relics | NIKKE | Prydwen Institute"
    description="What are Lost Relics and where to find them."
  />
);
